import "./section-media.css";
import photo4 from "../../assets/photo4.jpg";
import { FormattedMessage } from "react-intl";
import FeatherIcon from "feather-icons-react";

function SectionMedia() {
  return (
    <div className="section-media" id="scrollto">
      <div className="text">
        <h2 className="titel">
          <FormattedMessage id="app.mediaTitle" values={{ br: <br /> }} />
        </h2>
        <h4 className="subtitel">
          <FormattedMessage id="app.mediaContent" values={{ br: <br /> }} />
        </h4>
        <h6 className="laatste">
          <a
            href="https://www.flickr.com/photos/156991933@N04/sets/72177720306468307/"
            target="_blank"
            rel="noreferrer"
          >
            2023 - LOODS 66 - van schuilplaats tot pakhuis (Marc Faes)
          </a>
        </h6>
        <p className="content">
          <h6>
            Foto’s van onze huisfotograaf, Jean Vandijck:
            <a
              href="https://www.flickr.com/photos/187318825@N08/albums/72177720299095783"
              target="_blank"
              rel="noreferrer"
            >
              2022 – Memory Lane
            </a>
            <a
              href="https://www.flickr.com/photos/187318825@N08/albums/72177720299088797"
              target="_blank"
              rel="noreferrer"
            >
              2018 – Beyond Voices
            </a>
            <a
              href="https://www.flickr.com/photos/187318825@N08/albums/72177720299088682"
              target="_blank"
              rel="noreferrer"
            >
              2017 – a journey in Motion
            </a>
            <a
              href="https://www.flickr.com/photos/187318825@N08/albums/72177720299092964"
              target="_blank"
              rel="noreferrer"
            >
              2016 – De oneindigheid van de Canon
            </a>
            <a
              href="https://www.flickr.com/photos/187318825@N08/albums/72177720299088542"
              target="_blank"
              rel="noreferrer"
            >
              2014 – Prelude van ’n doorkijkreis
            </a>
            <a
              href="https://www.flickr.com/photos/187318825@N08/albums/72177720299088502"
              target="_blank"
              rel="noreferrer"
            >
              2013 – Nordvendt
            </a>
            <a
              href="https://www.flickr.com/photos/187318825@N08/albums/72177720299089150"
              target="_blank"
              rel="noreferrer"
            >
              2012 – Danscapes
            </a>
            <a
              href="https://www.flickr.com/photos/187318825@N08/albums/72177720299088402"
              target="_blank"
              rel="noreferrer"
            >
              2011 – Stemmendans
            </a>
            <a
              href="https://www.flickr.com/photos/187318825@N08/albums/72177720299084961"
              target="_blank"
              rel="noreferrer"
            >
              2010 – Cascade
            </a>
            <a
              href="https://www.flickr.com/photos/187318825@N08/albums/72177720299088900"
              target="_blank"
              rel="noreferrer"
            >
              2008 – A media Luz:
            </a>
            <a
              href="https://www.flickr.com/photos/187318825@N08/albums/72177720299088845"
              target="_blank"
              rel="noreferrer"
            >
              2007 – Aureus:{" "}
            </a>
            <a
              href="https://www.flickr.com/photos/187318825@N08/albums/72177720299095078"
              target="_blank"
              rel="noreferrer"
            >
              2006 – Sporen:{" "}
            </a>
            <a
              href="https://www.flickr.com/photos/187318825@N08/albums/72177720299092509"
              target="_blank"
              rel="noreferrer"
            >
              2005 – After a weil:{" "}
            </a>
            <a
              href="https://www.flickr.com/photos/187318825@N08/albums/72177720299092414"
              target="_blank"
              rel="noreferrer"
            >
              2004 – De drempel van de stilte:{" "}
            </a>
            <a
              href="https://www.flickr.com/photos/187318825@N08/albums/72177720299088077"
              target="_blank"
              rel="noreferrer"
            >
              2003 – IT is I.T. :{" "}
            </a>
            <a
              href="https://www.flickr.com/photos/187318825@N08/albums/72177720299092264"
              target="_blank"
              rel="noreferrer"
            >
              2002 – Van dwaze woorden en verre oorden:{" "}
            </a>
            <a
              href="https://www.flickr.com/photos/187318825@N08/albums/72177720299084436"
              target="_blank"
              rel="noreferrer"
            >
              2001 – Tresbattrementen:{" "}
            </a>
            <a
              href="https://www.flickr.com/photos/187318825@N08/albums/72177720299094683"
              target="_blank"
              rel="noreferrer"
            >
              2000 – Hidden shades of Being:{" "}
            </a>
          </h6>
          <h6>
            <a
              href="https://2021.fotopelt.be/2021/10/10/imago-tijl-herleeft-9-10-okt-21-gerard-coenen/"
              target="_blank"
              rel="noreferrer"
            >
              Foto’s van Imago Tijl herleeft (Gerard Coenen)
            </a>
          </h6>
        </p>
      </div>
      <img src={photo4}></img>
    </div>
  );
}

export default SectionMedia;
