import React from "react";
import "./ProjectTitle.css";

const ProjectTitle = (props) => {
  return (
    <div className="project">
      <h1 className="projectTitle">Imago Tijl</h1>
    </div>
  );
};

export default ProjectTitle;
