import React from "react";
import ProjectTitle from "../ProjecTitle/ProjectTitle";
import Socials from "../Socials/Socials";
import VideoBackground from "../VideoBackground/VideoBackground";
import Spacer from "../Spacer/Spacer";
import Footer from "../../main/footer/Footer";

const Repetities = () => {
  return (
    <div className="repetitiesDiv">
      <div>
        <ProjectTitle />
        <Socials />
        <VideoBackground />
        <Spacer />
      </div>
      <div className="repetities">
        <h1>REPETITIES</h1>
        <h3>maandag:</h3>
        <p>
          15.45 u. - 16.45 u. : jongens 6e studiejaar met jongens 1ste en 2de
          jaar
        </p>
        <br />
        <hr />
        <h3>woensdag:</h3>
        <p>12.30 u. - 14.00 u. : meisjes 1ste jaar (2 groepen)</p>
        <br />
        <hr />
        <h3>Vrijdag:</h3>
        <p>15.45 u. - 16.45 u. : meisjes 2de jaar</p>
      </div>
      <Spacer />
      <Spacer />
      <Footer />
      <Spacer />
    </div>
  );
};

export default Repetities;
