import React from "react";
import { Route, Switch } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";

import App from "./App";
import Repetities from "./components/Repetities/Repetities";

const Hero = () => {
  return (
    <Router forceRefresh={true}>
      <Switch>
        <Route path="/" exact component={App} />
        <Route
          exact
          path="/tickets"
          render={() =>
            (window.location =
              "https://app.ticketmatic.be/a/dommelhof/flow/events/production/10138")
          }
        />
        <Route exact path="/repetities" component={Repetities} />
        <Route path="*" component={App} />
      </Switch>
    </Router>
  );
};

export default Hero;
