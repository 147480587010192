import "./tickets.css";
import FeatherIcon from "feather-icons-react";
import { FormattedMessage } from "react-intl";

function Tickets() {
  return (
    <div className="tickets">
      {/*  <div className="event">
        <div className="dateandname">
          <div className="date">
            <h2>3-4-5</h2>
            <div className="weekmonth">
              <h6>MAART</h6>
              <h6>2023</h6>
            </div>
          </div>
          <h2>Première, Dommelhof Pelt</h2>
        </div>
        <div className="ticket">
          <h6>
            <a
              href="https://ticketshop.ticketmatic.com/dommelhof/imagotijl"
              target="_blank"
              rel="noreferrer"
            >
              Bestel uw tickets
            </a>
          </h6>
        </div>
      </div> */}
      {/*  <div className="event">
        <div className="dateandname">
          <div className="date">
            <h2>28</h2>
            <div className="weekmonth">
              <h6>APRIL</h6>
              <h6>2023</h6>
            </div>
            <h2></h2>
            <div className="weekmonth">
              <h6>MEI</h6>
              <h6></h6>
            </div>
          </div>
          <h2>EMJ Neerpelt</h2>
        </div>
        <div className="ticket">
          <h6>
            <a>
                <FormattedMessage id="app.freeentrance" />
              <FeatherIcon size="20" className="icon" icon="music" /> 
            </a>
          </h6>
        </div>
      </div> */}
      <div className="event">
        <div className="dateandname">
          <div className="date">
            <h2>17</h2>
            <div className="weekmonth">
              <h6>MEI</h6>
              <h6>2024</h6>
            </div>
          </div>
          <h2>Prille Cadansen</h2>
        </div>
        <div className="ticket">
          <h6>
            <a
              href="https://app.ticketmatic.be/a/dommelhof/flow/events/production/10138"
              target="_blank"
              rel="noreferrer"
            >
              Bestel uw tickets
            </a>
          </h6>
        </div>
      </div>
      <div className="event">
        <div className="dateandname">
          <div className="date">
            <h2>18</h2>
            <div className="weekmonth">
              <h6>MEI</h6>
              <h6>2024</h6>
            </div>
          </div>
          <h2>Prille Cadansen</h2>
        </div>
        <div className="ticket">
          <h6>
            <a
              href="https://app.ticketmatic.be/a/dommelhof/flow/events/production/10138"
              target="_blank"
              rel="noreferrer"
            >
              Bestel uw tickets
            </a>
          </h6>
        </div>
      </div>
    </div>
  );
}

export default Tickets;
